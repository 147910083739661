<template>
  <div class="full-area padded vertically-centered red-screen">
    <h2>Las cosas no están funcionando entre los dos <br>¯\_(ツ)_/¯</h2>
    <p>Por favor usa tu dispositivo en orientación vertical. Si estás en un computador, mejor usa tu celular :) </p>
  </div>
</template>

<script>
export default {
  name: 'UsePortraitScreen'
}
</script>

<style scoped lang="scss">
  .red-screen {
    background: $color-emphasis;
    color: white;
    font-size: 0.6em;
  }
</style>
